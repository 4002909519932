.gallery {
    display: flex;
    max-width: 840px;
    width: 80%;
    overflow: auto;
    margin: 0 auto;

    &-page {
        & h1 {
            text-align: center;
            color: rgb(22, 198, 12);
        }
    }
    &-item {
        width: 100px;
        display: flex;
        flex-shrink: 0;
        height: 100px;
        background-color: transparent;
        margin: 20px;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 32px;
        transition: .3s;

        &:hover {
            width: 120px;
            height: 120px;
            font-weight: 700;
            margin: 10px;
            transition: .1s;
        }

        &.active-image {
            width: 120px;
            height: 120px;
            font-weight: 700;
            margin: 10px;
        }
    }
    &-image {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        box-shadow: 0px 0 5px 0px black;
    }
    &-selected-item {
        width: 80%;
        height: auto;
        max-width: 840px;
        background-color: transparent;
        margin: 16px auto;
    }
}
