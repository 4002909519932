.App {
    width: 80%;
    color: white;
    margin: 0 auto;
}
.App, .App input {
    font-family: Consolas;
}
.post {
    display: flex;
    padding: 15px;
    border: 2px solid rgb(22, 198, 12);
    background-color: black;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
    color: rgb(22, 198, 12);
}
.post-enter {
    opacity: 0;
    transform: translateX(-350px);
}
.post-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 300ms ease-in;
}
.post-exit {
    opacity: 1;
}
.post-exit-active {
    opacity: 0;
    transform: translateX(-350px);
    transition: all 300ms ease-in;
}
.page__wrapper {
    display: flex;
    justify-content: center;
    margin: 24px auto;
}
.page {
    padding: 6px 10px;
    margin: 6px;
    border: 1px solid rgb(22, 198, 12);
    cursor: pointer;
}
.page__current {
    font-weight: bold;
    border: 2px solid rgb(22, 198, 12);
}
.post__btns {
    display: flex;
}