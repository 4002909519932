@import '~bootstrap/dist/css/bootstrap.css';

body {
  margin: 0;
  padding: 0;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*, :after, :before {
  box-sizing: border-box;
  border: 0 solid;
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
a, a:visited {
  text-decoration: none;
  color: black;
}
#root {
  height: 100%;
  min-height: 100vh;
  min-width: 320px;
  background-image: url('./resources/jpg/background-min.jpg');
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 12px;
  background-color: #110a12;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #000000;
}
::-webkit-scrollbar-thumb {
  background-color: #16c60c;
  border-radius: 4px;
  border: 2px solid black;
}
