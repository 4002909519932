.myBtn {
    margin: 12px;
    margin-left: auto;
    padding: 6px 16px;
    color: rgb(22, 198, 12);
    font-size: 14px;
    background: transparent;
    border: 1px solid rgb(22, 198, 12);
    border-radius: 3px;
    cursor: pointer;
}