.login {
    &-container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, .3);
    }
    &-block {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, .8);
        border-radius: 10px;
        padding: 24px;
        color: rgb(22, 198, 12);
    }
    &-button {
        margin-top: 12px;
        margin-left: auto;
    }
}