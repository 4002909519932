.page__header {
    width: 100%;
    background-color: rgba(0, 0, 0, .2);
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-logo {
        height: 80px;
        width: 80px;
        display: flex;
        align-items: center;
        padding: 12px;
        text-transform: uppercase;
        cursor: pointer;
        background: #ef32d9;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #89fffd, #ef32d9);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #ef32d9, #89fffd, #ef32d9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        mask: url('../resources/svg/smc-logo-black.svg');
        transition: 1000ms;
        background-position-x: 0px;

        &:hover {
            background-position-x: 80px;
        }
        & img {
            height: 60px;
        }
    }

    .main-nav {
        position: relative;
    }

    & a {
        color: white;
        font-size: 20px;
        font-weight: 500;
    }

    & .nav-links {
        display: flex;
        align-items: center;

        & .first-link {
            margin-left: auto;
        }

        & li {
            font-size: 20px;
            font-weight: 500;
            text-transform: uppercase;
            cursor: pointer;
            border: 2px solid transparent;

            & a {
                display: inline-block;
                padding: 12px;
            }

            &:hover {
                border: 2px solid white;
            }
        }
    }
}

.hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2;
    transform: translate(0, -50%);
}

.hamburger:hover {
    opacity: 0.7;
}

.hamburger.is-active:hover {
    opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: #FFF;
}

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #FFF;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -10px;
}

.hamburger-inner::after {
    bottom: -10px;
}

.hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger~.nav-links {
    top: -500px;
    transition-duration: 0.3s;
}

@media screen and (min-width: 991px) {
    .hamburger {
        display: none;
    }
}
@media screen and (max-width: 991px) {
    .page__header {
        .hamburger.is-active~.nav-links {
            top: 40px;
        }
        & .nav-links {
            position: absolute;
            right: 0;
            flex-direction: column;
            align-items: flex-start;
            max-width: 300px;
            background-color: rgba(0, 0, 0, 0.8);
            width: 250px;
            z-index: 99;

            & .first-link {
                margin-left: 0;
            }
            & li {
                position: relative;
                width: 100%;

                &:not(:last-child)::after {
                    position: absolute;
                    bottom: -2px;
                    left: 5%;
                    content: '';
                    width: 90%;
                    height: 1px;
                    background-color: white;
                }
            }

        }
    }
}
