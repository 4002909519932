.cv {
    &-page-wrapper {
        background-color: rgba(255, 255, 255, .8);
        color: black;
        font-size: 16px;
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
        max-width: 720px;
        margin: 32px auto;

        & h1 {
            width: 100%;
            text-align: center;
        }
        & h2 {
            width: 100%;
            text-align: center;
            margin-bottom: 24px;
        }
        & p {
            font-weight: 500;
            padding: 8px;
        }
    }
    &-block {
        margin-bottom: 32px;
    }
    &-main-info {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 32px;

        &-item {
            margin: 0;
            padding: 8px;
            width: 50%;
        }
    }
    &-skills {
        &-list {
            display: flex;
            flex-wrap: wrap;

            & li {
                padding: 6px;
                width: calc(25% - 8px);
            }
        }
    }
    &-language {
        width: 100%;
    }
    &-education {
        & p {
            position: relative;
            &:not(:last-child):after {
                content: '';
                position: absolute;
                bottom: -1px;
                left: 15%;
                right: 15%;
                height: 1px;
                background-color: black;
            }
        }
    }
    &-employment {
        & p {
            position: relative;
            &:not(:last-child):after {
                content: '';
                position: absolute;
                bottom: -1px;
                left: 15%;
                right: 15%;
                height: 1px;
                background-color: black;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .cv {
        &-page-wrapper {
            padding: 14px;
        }
        &-skills {
            &-list {
                & li {
                    padding: 8px;
                    width: calc(33.3%);
                }
            }
        }

    }
}

.api-response-container {
    font-size: 14px;
    line-height: 1.4;
}

.api-response-line {
    margin-bottom: 5px;
}

.code-block {
    padding: 10px;
    background-color: #f6f8fa;
    border-radius: 4px;
}

/* SyntaxHighlighter overrides */
.code-block code {
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    background: none;
    border: none;
}
.code-wrapper {
    background-color: #282c34;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
}

.code-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.language {
    font-size: 14px;
    font-weight: bold;
    color: #61dafb;
    margin-right: 10px;
}

.copy-button {
    border: none;
    background-color: transparent;
    color: #61dafb;
    cursor: pointer;
    font-size: 18px;
    padding: 0;
    display: flex;
    align-items: center;
}

.copy-button:hover {
    color: #fff;
}

.code-block {
    font-size: 14px;
    font-family: 'Courier New', Courier, monospace;
    overflow-x: auto;
}

// Optional: Adjust the scrollbar styles for the code block
.code-block::-webkit-scrollbar {
    width: 8px;
}

.code-block::-webkit-scrollbar-thumb {
    background-color: #61dafb;
    border-radius: 4px;
}

.code-block::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
}
.query-textarea {
    resize: none;
}

.query-wrapper {
    position: fixed;
    bottom: 5px;
    left: 10%;
    width: 80%;
}

.response-wrapper {
    position: fixed;
    top: 90px;
    left: 10%;
    height: 73%;
    width: 80%;
    overflow-y: auto;
    background-color: rgba(0,0,0,.3);
    border-radius: 7px;
    padding: 16px;
}

.query-text {
    font-style: italic;
    font-size: 14px;
}
